.container {
  display: flex;
  align-items: center;
  justify-content: center;

  #redblocks > * {
    transform: translate3d(160px, -93px, 0);
    animation: moveblocks 4s 1s ease infinite;
  }

  @keyframes moveblocks {
    0% {
      transform: translate3d(160px, -93px, 0);
    }

    50%,
    100% {
      transform: translate(0);
    }
  }

  #firstBlock {
    transform: translate3d(160px, -93px, 0);
    animation: firstBlock 4s 1s ease infinite;
  }

  #blockdis {
    animation: blockdis 4s 1s ease infinite;
  }

  @keyframes blockdis {
    30% {
      opacity: 1;
    }

    40%,
    100% {
      opacity: 0;
      transform: translate3d(-160px, 93px, 0);
    }
  }

  @keyframes firstBlock {
    0%,
    15% {
      opacity: 0;
    }

    40%,
    100% {
      opacity: 1;
    }
  }

  @for $i from 1 to 5 {
    #redblocksparticles g:nth-child(#{$i}) polygon {
      opacity: 0.35;
      animation: glow 4s 1s ease infinite;
    }
  }

  @for $i from 1 to 20 {
    #redblocksparticles g:nth-child(1) g circle:nth-child(#{$i}) {
      animation: dots 4s #{1000 + $i * 50}ms ease infinite;
    }

    #redblocksparticles g:nth-child(2) g circle:nth-child(#{$i}) {
      animation: dots 4s #{1000 + $i * 50}ms ease infinite;
    }

    #redblocksparticles g:nth-child(3) g circle:nth-child(#{$i}) {
      animation: dots 4s #{1000 + $i * 50}ms ease infinite;
    }

    #redblocksparticles g:nth-child(4) g circle:nth-child(#{$i}) {
      animation: dots 4s #{1000 + $i * 50}ms ease infinite;
    }
  }

  @keyframes glow {
    0%,
    45% {
      opacity: 0;
    }

    60%,
    100% {
      opacity: 0.35;
    }
  }

  @keyframes dots {
    0%,
    35% {
      transform: translate(0);
      opacity: 0;
    }

    60%,
    80% {
      transform: translate(55px, -35px);
      opacity: 1;
    }

    100% {
      transform: translate(55px, -35px);
      opacity: 0;
    }
  }

  @for $i from 1 to 22 {
    #purplebg > :nth-child(#{$i}) {
      animation: up 2s #{$i * 500}ms ease infinite alternate;
    }
  }

  @keyframes up {
    to {
      transform: translate(0, -25px);
    }
  }

  @for $i from 1 to 13 {
    #bottomparticles > :nth-child(#{$i}) {
      animation: particles 4s #{$i * 300}ms ease infinite alternate, p 2s ease infinite alternate;
    }

    #bottomparticles2 > :nth-child(#{$i}) {
      animation: particles 4s #{$i * 300}ms ease infinite alternate, p 2s ease infinite alternate;
    }

    #redglowparticles > :nth-child(#{$i}) {
      animation: particles 4s #{$i * 300}ms ease infinite alternate, p 2s ease infinite alternate;
    }
  }

  @keyframes p {
    85%,
    100% {
      opacity: 0;
    }
  }

  @keyframes particles {
    0%,
    100% {
      transform: translate(0);
    }

    50% {
      transform: translate(10px, 15px);
    }
  }

  .particlespoly {
    animation: p 2s ease infinite alternate;
  }

  @for $i from 1 to 4 {
    #d-app g:nth-child(#{$i}) {
      animation: updown 2s #{$i * 200}ms ease-in-out infinite alternate;
    }
  }

  @for $i from 1 to 4 {
    #d-apps2 g:nth-child(#{$i}) {
      animation: updown 2s #{$i * 200}ms ease-in-out infinite alternate;
    }
  }

  @for $i from 1 to 20 {
    #d-apps2wrapper g:nth-child(3) g > circle:nth-child(#{$i}) {
      animation: updown 2s #{$i * 50}ms ease infinite alternate;
    }
  }

  @for $i from 1 to 20 {
    #d-appswrapper g:nth-child(2) g > circle:nth-child(#{$i}) {
      animation: updown 2s #{$i * 50}ms ease infinite alternate;
    }
  }

  @keyframes updown {
    100% {
      transform: translate(0, -20px);
    }
  }

  #Layer_1 > g:nth-child(2) > g:nth-child(18) > g:nth-child(12) {
    animation: arrows 1s ease-in-out infinite alternate, p 2s ease infinite alternate;
  }

  @keyframes arrows {
    to {
      transform: translate(25px, 25px);
    }
  }

  #Layer_1 > g:nth-child(2) > g:nth-child(17) > g:nth-child(13) {
    animation: arrows2 1s ease-in-out infinite alternate, p 2s ease infinite alternate;
  }

  @keyframes arrows2 {
    to {
      transform: translate(-25px, -25px);
    }
  }

  #Layer_1 > g:nth-child(2) > g:nth-child(17) > g:nth-child(12) {
    animation: arrows 1s ease-in-out infinite alternate, p 2s ease infinite alternate;
  }
}
